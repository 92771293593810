import { lazy, useMemo } from "react";
const PageRoutable = lazy(
  () => import("./page-routable-Dqbx8Lx4.js").then((module) => ({
    default: module.PageRoutable
  }))
);
const usePageRoutable = () => {
  return useMemo(
    () => ({
      page: {
        component: PageRoutable
      }
    }),
    []
  );
};
export {
  usePageRoutable
};
